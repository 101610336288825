import * as React from "react";
import { Header } from "app/subframe/components/Header";
import { SuccessWell } from "app/subframe/components/SuccessWell";
import { PersonInfo } from "app/subframe/components/PersonInfo";
import { ServiceLineItem } from "app/subframe/components/ServiceLineItem";
import { LineItems } from "app/subframe/components/LineItems";
import { usePaymentSuccess } from "./usePaymentSuccess";
import { titleize } from "app/utils/string";
import { format } from "date-fns";
import { Loading } from "app/components/loading/loading";
import { AsButton, AsInput, CardInfo, ReceiptEnd } from "app/subframe";

export interface PaymentSuccessProps {}

export const PaymentSuccess: React.FC<PaymentSuccessProps> = (props) => {
  const {
    sale,
    paymentLinkInfo,
    emailToSendReceipt,
    setEmailToSendReceipt,
    onSendReceiptWithEmail,
    loading,
  } = usePaymentSuccess();

  if (loading || !paymentLinkInfo.paymentTransaction) {
    console.log("Loading");
    return <Loading fullScreen showBackdrop label="Getting Invoice" />;
  }

  return (
    <div className="container max-w-none flex w-full flex-col items-center gap-4 bg-millet-50 mobile:flex-col mobile:items-center mobile:justify-start mobile:gap-2 mobile:bg-millet-50 mobile:px-0 mobile:py-0">
      <Header
        className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
        image={paymentLinkInfo.companyLogo}
        text={paymentLinkInfo.companyName}
      />
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-6 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
        <SuccessWell
          onSubmit={onSendReceiptWithEmail}
          title="Payment Succedded"
          invoiceTitle="Invoice #"
          inviteValue={`${sale.id}`}
          paymentDateTitle="Payment date"
          paymentDateValue={format(
            paymentLinkInfo.paymentTransaction.createdAt,
            "M.dd.yyyy"
          )}
          paymentMethodTitle="Payment method"
          cardInfoElement={
            <CardInfo
              image="https://res.cloudinary.com/subframe/image/upload/v1726598383/uploads/3988/dvl5twcraiwmn914brec.png"
              text={paymentLinkInfo.paymentTransaction.last4}
            />
          }
          emailInputElement={
            <AsInput
              value={emailToSendReceipt}
              onChange={(event) => setEmailToSendReceipt(event.target.value)}
              textPosition="left"
              error={false}
              type="email"
              showLeadingIcon={false}
            />
          }
          emailButtonElement={
            <AsButton
              className="h-12 w-full flex-none"
              variant="secondary"
              text="Email me this receipt"
              size="md"
              type="submit"
            />
          }
        />
        <PersonInfo
          className="h-auto w-full flex-none mobile:h-auto mobile:w-full mobile:flex-none"
          name={titleize(sale.client.name)}
          subtitle={`Serviced on ${
            sale?.appointmentGroup?.appointments.length > 0
              ? format(
                  new Date(sale?.appointmentGroup?.appointments[0]?.endTime),
                  "M.dd.yyyy"
                )
              : format(new Date(), "M.dd.yyyy")
          }`}
        />
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100 mobile:items-center mobile:justify-center mobile:bg-neutral-100" />
        {sale.paidMerchantServices.map((service, index) => (
          <ServiceLineItem
            key={index}
            serviceName={service.title}
            conjunction="with"
            staff={service.employee.name}
            price={service.price}
            paidStatus={sale.unpaidMerchantServices.length ? "paid" : "default"}
          />
        ))}
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
        <LineItems title="Subtotal" price={sale.subtotal} />
        <LineItems title="Tax" price={sale.paidTax} />
        <LineItems title="Tip" price={sale.paidTips} />
        <LineItems title="Total" price={sale.paidBalance} />
      </div>
      <ReceiptEnd
        className="w-full"
        image="https://res.cloudinary.com/subframe/image/upload/v1726699577/uploads/3988/fw4c3atrh8tmapk0hk7t.svg"
      />
    </div>
  );
};
